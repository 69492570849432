import React, { useRef, useState, useEffect } from 'react';
import { graphql } from 'gatsby';
import {
  Layout,
  SEO,
  LWrap,
  CJumbotron,
  CAnchorNav,
  CSectTitle03,
  CSpLabelTable,
  CBreadCrumb,
  CSearch,
  CBtnList,
} from '../../../components/_index';
import '../../../assets/_sass/page/surroundings.scss';

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter;

  return (
    <Layout>
      <SEO title={frontmatter?.title} description={frontmatter?.description} />
      <CJumbotron
        size="large"
        data={{
          title: {
            main: 'ホテル周辺のご案内',
            sub: 'INFORMATION AROUND THE HOTEL',
          },
          imgList: [
            {
              img: {
                src: '/assets/images/about/surroundings/kv.png',
              },
              imgSp: {
                src: '/assets/images/about/surroundings/kv__sp.png',
              },
            },
          ],
        }}
      ></CJumbotron>

      <div className="p_surroundings">
        <section className="u_pt100">
          <LWrap>
            <CAnchorNav
              exClass="u_mb0 u_mb100"
              data={[
                {
                  title: <>ホテル周辺ガイド</>,
                  link: {
                    href: '#hotel',
                  },
                },
                {
                  title: <>スポットガイド</>,
                  link: {
                    href: '#spot',
                  },
                },
              ]}
            />
          </LWrap>
        </section>

        <section id="hotel" className="u_pt100">
          <LWrap>
            <CSectTitle03 title="ホテル周辺ガイド" />
            <CSpLabelTable
              data={{
                header: [
                  '地域',
                  '路線・最寄り駅',
                  '乗り換え及び所要時間',
                  'スポット',
                ],
                body: [
                  {
                    item: [
                      <>水天宮前</>,
                      <>東京メトロ半蔵門線「水天宮前」駅</>,
                      <>4番出口直結</>,
                      <>
                        <ul className="c_circleList">
                          <li>水天宮</li>
                          <li>東京シティエアターミナル（T-CAT）</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>人形町</>,
                      <>東京メトロ日比谷線・都営浅草線「人形町」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>
                            ホテルから東京メトロ日比谷線「人形町」駅（A2出口）徒歩約5分
                          </li>
                          <li>
                            ホテルから都営浅草線「人形町」駅（A3出口）徒歩約8分
                          </li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>甘酒横丁</li>
                          <li>明治座</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>東京</>,
                      <>東京メトロ丸ノ内線「東京」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>ホテルから車で約10分</li>
                          <li>
                            東京メトロ半蔵門線「水天宮前」駅から「大手町」駅
                          </li>
                          <li>
                            東京メトロ丸ノ内線「大手町」駅乗り換え約15分
                            <br className="u_pc" />
                            もしくは「大手町」駅から徒歩
                          </li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>JR東京駅</li>
                          <li>皇居</li>
                          <li>丸の内</li>
                          <li>丸ビル・新丸ビル</li>
                          <li>丸の内オアゾ</li>
                          <li>大手町ビジネス街</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>両国</>,
                      <>JR総武線「両国」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>ホテルから車で約10分</li>
                          <li>
                            都営浅草線「人形町」駅から都営浅草線「浅草橋」駅乗り換え
                          </li>
                          <li>JR総武線「浅草橋」駅乗り換え約15分</li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>江戸東京博物館</li>
                          <li>国技館</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>清澄白河</>,
                      <>東京メトロ半蔵門線「清澄白河」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>ホテルから車で約5分</li>
                          <li>東京メトロ半蔵門線「水天宮前」駅から約5分</li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>東京都現代美術館</li>
                          <li>清澄庭園</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>上野</>,
                      <>東京メトロ日比谷線 「上野」駅</>,
                      <>東京メトロ日比谷線「人形町」駅から約15分</>,
                      <>
                        <ul className="c_circleList">
                          <li>東京都美術館</li>
                          <li>上野動物園</li>
                          <li>上野公園</li>
                          <li>不忍池</li>
                          <li>アメヤ横丁（アメ横）</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>浅草</>,
                      <>都営浅草線「浅草」駅</>,
                      <>都営浅草線「人形町」駅から約10分</>,
                      <>
                        <ul className="c_circleList">
                          <li>雷門</li>
                          <li>仲見世</li>
                          <li>浅草寺</li>
                          <li>隅田公園</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>日本橋</>,
                      <>
                        <ul className="c_circleList">
                          <li>都営浅草線「日本橋」駅</li>
                          <li>都営浅草線「人形町」駅から約7分</li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>東京メトロ半蔵門線「三越前」駅</li>
                          <li>東京メトロ半蔵門線「水天宮前」駅から約5分</li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>お江戸日本橋亭</li>
                          <li>三越・高島屋</li>
                          <li>COREDO日本橋</li>
                          <li>COREDO室町</li>
                          <li>貨幣博物館</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>銀座</>,
                      <>東京メトロ日比谷線「銀座」駅 「東銀座」駅</>,
                      <>東京メトロ日比谷線「人形町」駅から約15分</>,
                      <>
                        <ul className="c_circleList">
                          <li>日比谷公園</li>
                          <li>歌舞伎座</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>新橋（汐留）</>,
                      <>都営浅草線「新橋」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>都営浅草線「人形町」駅から約15分</li>
                          <li>
                            都営大江戸線「汐留」駅までは「新橋」駅より
                            <br className="u_pc" />
                            徒歩約3分
                          </li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>新橋演舞場</li>
                          <li>浜離宮</li>
                          <li>カレッタ汐留</li>
                        </ul>
                      </>,
                    ],
                  },
                ],
              }}
            />
          </LWrap>
        </section>

        <section id="spot" className="u_pt100 u_pb100">
          <LWrap>
            <CSectTitle03 title="スポットガイド" />
            <CSpLabelTable
              data={{
                header: [
                  '地域',
                  '路線・最寄り駅',
                  '乗り換え及び所要時間',
                  'スポット',
                ],
                body: [
                  {
                    item: [
                      <>六本木</>,
                      <>東京メトロ日比谷線「六本木」駅</>,
                      <>東京メトロ日比谷線「人形町」駅から約25分</>,
                      <>
                        <ul className="c_circleList">
                          <li>六本木ヒルズ</li>
                          <li>東京ミッドタウン</li>
                          <li>サントリーホール</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>神谷町</>,
                      <>東京メトロ日比谷線「神谷町」駅</>,
                      <>東京メトロ日比谷線「人形町」駅から約20分</>,
                      <>東京タワー</>,
                    ],
                  },
                  {
                    item: [
                      <>表参道</>,
                      <>東京メトロ半蔵門線 「表参道」駅</>,
                      <>東京メトロ半蔵門線「水天宮前」駅から約20分</>,
                      <>表参道ヒルズ</>,
                    ],
                  },
                  {
                    item: [
                      <>後楽園</>,
                      <>東京メトロ丸ノ内線 「後楽園」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>
                            東京メトロ半蔵門線「水天宮前」駅から「大手町」駅
                          </li>
                          <li>東京メトロ丸ノ内線「大手町」駅乗り換え約20分</li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>東京ドームシティ</li>
                          <li>小石川後楽園</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>池袋</>,
                      <>東京メトロ丸ノ内線「池袋」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>
                            東京メトロ半蔵門線「水天宮前」駅から「大手町」駅
                          </li>
                          <li>東京メトロ丸ノ内線「大手町」駅乗り換え約25分</li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>サンシャイン水族館</li>
                          <li>東京芸術劇場</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>葛西</>,
                      <>JR京葉線「葛西臨海公園」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>
                            東京メトロ日比谷線「人形町」駅から「八丁堀」駅
                          </li>
                          <li>JR京葉線「八丁堀」駅乗り換え約20分</li>
                        </ul>
                      </>,
                      <>葛西臨海公園</>,
                    ],
                  },
                  {
                    item: [
                      <>舞浜</>,
                      <>JR京葉線 「舞浜」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>ホテルから車で約20分</li>
                          <li>
                            東京メトロ日比谷線「人形町」駅から「八丁堀」駅
                            <br className="u_pc" />
                            JR京葉線「八丁堀」駅乗り換え約20分
                          </li>
                        </ul>
                      </>,
                      <>東京ディズニーリゾートⓇ</>,
                    ],
                  },
                  {
                    item: [
                      <>台場</>,
                      <>ゆりかもめ「お台場海浜公園」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>ホテルから車で約20分</li>
                          <li>
                            都営浅草線「人形町」駅から「新橋」駅
                            <br className="u_pc" />
                            ゆりかもめ「新橋」駅乗り換え約40分
                          </li>
                        </ul>
                      </>,
                      <>
                        <ul className="c_circleList">
                          <li>お台場海浜公園</li>
                          <li>レインボーブリッジ</li>
                          <li>アクアシティお台場</li>
                        </ul>
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>成田国際空港</>,
                      <>京成本線「成田空港」駅</>,
                      <>
                        ホテルから車で約60分（T-CATからリムジンバス
                        <br className="u_pc" />
                        をご利用で約55分）
                      </>,
                    ],
                  },
                  {
                    item: [
                      <>
                        羽田空港
                        <br className="u_pc" />
                        （東京国際空港）
                      </>,
                      <>京急空港線「羽田空港」駅</>,
                      <>
                        <ul className="c_circleList">
                          <li>
                            ホテルから車で約30分（T-CATからリムジンバスをご利用で約25分）
                          </li>
                          <li>都営浅草線「人形町」駅から約35分</li>
                        </ul>
                      </>,
                    ],
                  },
                ],
              }}
            />
            <CBtnList
              exClass="u_mt100_pc"
              data={[
                {
                  label: '観光に便利なサービス',
                  size: 'large',
                  link: {
                    href: '/about/tourism/',
                  },
                  color: 'borderBlack',
                },
              ]}
            />
          </LWrap>
        </section>
      </div>

      <CBreadCrumb
        data={{
          parent: [
            {
              label: 'ホテルについて',
              path: '/about/',
            },
          ],
          current: {
            label: frontmatter?.title,
          },
        }}
      />
      <CSearch formId="global" />
    </Layout>
  );
};

export default SubPage;

export const query = graphql`
  query ($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`;
